<template>
  <div class="coupon-container">
    <van-list
      v-if="couponList.length"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
    >
      <div class="coupon-list">
        <div
          v-for="(item,index) in couponList"
          :key="index"
          @click="goCouponInfo(index)"
        >
          <div
            class="coupon-item row-center"
            :class="item.couponType == 1 && activeIndex != index ? 'coupon-item1' : item.couponType == 1 && activeIndex == index ? 'coupon-item2' : item.couponType == 0 && activeIndex != index ? 'coupon-item4' : 'coupon-item3'"
          >
            <div class="coupon-item-info col-start">
              <div class="coupon-item-type row-center">{{item.couponType == 1 ? '限品满减券' : '通用满减券'}}</div>
              <p class="coupon-item-title">{{ item.couponTitle.length >= 30 ? item.couponTitle.substring(0,30) : item.couponTitle }}</p>

              <p class="coupon-item-time">{{ item.createTime.substring(0,10)  + ' ~ ' + item.endTime.substring(0,10)}}</p>
              <p class="coupon-item-detail">详细信息</p>
            </div>
            <div class="coupon-price col-center">
              <p class="coupon-price-main">
                <span style="font-size: 18px;font-weight: 400 ;">¥</span>
                {{ item.couponPrice }}
              </p>
              <p class="coupon-price-tip">满{{item.useMinPrice}}元可用</p>
            </div>

          </div>
          <div
            v-if="activeIndex == index"
            class="coupon-item-tip"
          >
            <div
              style="white-space: pre-wrap;"
              v-html="item.direction"
            ></div>
            <p style="margin-top: 5px;">券编号：{{ item.code }}</p>
          </div>
        </div>
      </div>
    </van-list>
    <div
      v-else
      class="col-center"
      style="width:100%;height: calc(100vh - 7rem);"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
    </div>
  </div>
</template>
<script>
import api from '../../api/index-client'
import Vue from 'vue'
import { List } from 'vant';
Vue.use(List);
export default {
  data () {
    return {
      activeIndex: -1,
      couponList: [],
      loading: false,
      finished: false,
      noOrder: false,
      goodsParam: {
        page: 0,
        limit: 20,
      },
    }
  },
  mounted () {
    this.getCouponList()
  },
  methods: {
    load () {
      this.loading = true
      this.goodsParam.page += 1
      this.getCouponList()
    },
    // 优惠券列表
    getCouponList () {
      api
        .get('coupons/user?type=1&page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .then(result => {
          if (result.data.success) {
            result.data.data.map(i => {
              this.couponList.push(i)
            })
            if (result.data.data.length < this.goodsParam.limit) {
              this.finished = true
            } else {
              this.finished = false
            }
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 查看优惠券信息
    goCouponInfo (e) {
      this.activeIndex = this.activeIndex == e ? -1 : e
    }
  },
}
</script>
<style lang="less" scoped>
.coupon-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .coupon-list {
    padding-bottom: 20px;
  }
  .coupon-item {
    width: 92vw;
    height: 21vw;
    margin: 15px 4vw 0 4vw;
    position: relative;
  }
  .coupon-item1 {
    background-image: url('../../assets/icon/coupon/my-coupon.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
  }
  .coupon-item2 {
    background-image: url('../../assets/icon/coupon/my-coupon1.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
  }
  .coupon-item3 {
    background-image: url('../../assets/icon/coupon/my-coupon2.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
  }
  .coupon-item4 {
    background-image: url('../../assets/icon/coupon/my-coupon3.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
  }
  .coupon-item-tip {
    width: 86vw;
    min-height: 15vw;
    height: auto;
    margin: 15px 4vw 0 4vw;
    padding: 3vw;
    background: #ffffff;
    border-radius: 10px;
    color: #696969;
    font-size: 13px;
    font-weight: 400;
  }
  .coupon-item-info {
    width: 76.9%;
    height: 21vw;
    .coupon-item-type {
      position: absolute;
      top: 2vw;
      left: 3vw;
      font-size: 12px;
      color: #ffffff;
      width: 19vw;
      height: 5vw;
    }
    .coupon-item-title {
      position: absolute;
      top: 1.7vw;
      left: 3vw;
      width: 74%;
      height: 12vw;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-indent: 20vw;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .coupon-item-time {
      color: #696969;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      bottom: 5vw;
      left: 3vw;
    }
    .coupon-item-detail {
      color: #696969;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      bottom: 1vw;
      left: 3vw;
    }
  }
  .coupon-price {
    width: 23.1%;
    height: 21vw;
    .coupon-price-main {
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
    }
    .coupon-price-tip {
      font-size: 12px;
      color: #ffffff;
      font-weight: 300;
    }
  }
}
</style>